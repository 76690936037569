import {createTheme, responsiveFontSizes} from "@mui/material";

export const cernuscoTheme = responsiveFontSizes(createTheme({
    typography:{
        fontFamily:["Montserrat", "Sans serif"].join(","),
    },
    palette: {
        primary: {
            main: "#2CA8FF",
            contrastText:"#fff"
        },
        secondary: {
            main: "#F96332"
        }
    }
}))
