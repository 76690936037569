import React from "react";
import {
    Box,
    Checkbox,
    FormControl, FormControlLabel,
    FormGroup, FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField, Typography,
    RadioGroup,
    Radio, Divider
} from "@mui/material";
import {DateField, DatePicker, DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {FastField, useField} from "formik";

export const FormField = ({profile, label, ...props}) => {
    const [field, meta, helpers] = useField(props)

    return (
        <Grid item xs={props.cols ? props.cols.xs : 12} sm={props.cols ? props.cols.sm : 6}>
            {(() => {
                switch (props.type) {
                    case 'divider':
                        return <Divider/>
                    case 'note':
                        return <Box>
                            <Typography>
                                {label}
                            </Typography>
                        </Box>
                    case 'checkbox':
                        return <FormControl sx={{pt: 2}} fullWidth error={!!(meta.touched && meta.error)}>
                            <FormGroup>
                                <FormControlLabel
                                    disabled={profile}
                                    sx={{alignItems: 'start'}}
                                    control={<Checkbox sx={{pt: 0}} checked={field.value}
                                                       {...field}
                                                       {...props}
                                    />} label={label}
                                />
                            </FormGroup>
                            <FormHelperText>{meta.touched && meta.error}</FormHelperText>
                        </FormControl>
                    case 'radiogroup':
                        return <FormControl sx={{pt: 2}} variant="standard" fullWidth
                                            disabled={profile}
                                            error={!!(meta.touched && meta.error)}>
                            {label && <FormLabel id={props.id} sx={{color: 'black !important'}}>{label}</FormLabel>}
                            <RadioGroup
                                aria-labelledby={props.id}
                                defaultValue={''}
                                {...field}
                                {...props}
                                row={props.row ?? true}
                            >
                                {props.values?.map(v =>
                                    <FormControlLabel sx={{
                                        alignItems: props.row === false ? 'start' : 'center',
                                        py: props.row === false ? 1 : 0
                                    }} value={v.value} key={v.value}
                                                      control={<Radio sx={{py: props.row === false ? 0 : 1}}/>}
                                                      label={v.label}/>
                                )}
                            </RadioGroup>
                            <FormHelperText>{meta.touched && meta.error}</FormHelperText>
                        </FormControl>
                    case 'select':
                        return <FormControl variant="standard" fullWidth error={!!(meta.touched && meta.error)}>
                            {label && <InputLabel id={field.id}>{label}</InputLabel>}
                            <Select
                                disabled={profile}
                                label={label ?? ''}
                                {...field}
                                {...props}
                                helperText={meta.touched && meta.error}
                            >
                                {props.values?.map(v =>
                                    <MenuItem value={v.value} key={v}>{v.label}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{meta.touched && meta.error}</FormHelperText>
                        </FormControl>
                    case 'date':
                        return <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateField
                                variant={"standard"}
                                name={props.id}
                                disableFuture={props.disableFuture}
                                disablePast={props.disablePast}
                                id={props.id}
                                sx={{width: '100%'}}
                                format={'DD/MM/YYYY'}
                                value={props.formik.values[props.id]}
                                onChange={(value) => {
                                    props.formik.setFieldValue(props.id, value, true);
                                }}
                                error={!!(props.formik.touched[props.id] && props.formik.errors[props.id])}
                                helperText={props.formik.touched[props.id] && props.formik.errors[props.id]}
                                label={label ?? ''}
                            />
                        </LocalizationProvider>
                    default:
                        return <TextField autoComplete={''} error={!!(meta.touched && meta.error)}
                                          helperText={meta.touched && meta.error}
                                          {...field}
                                          {...props}
                                          disabled={(props.precompiled || profile)}
                                          label={label ?? ''} variant={"standard"}
                                          sx={{width: '100%'}}/>
                }
            })()}
        </Grid>
    )
}
