export const athleteMap = [
    {
        id: 'anagraficaAtleta',
        name: "anagraficaAtleta",
        title: 'Dati Anagrafici Atleta',
        subtitle: "",
        fields: [
            {
                id: 'nome',
                name: 'nome',
                label: 'Nome',
                type: 'text',
                required: true,
                validations: ['string', 'required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'cognome',
                name: 'cognome',
                label: 'Cognome',
                type: 'text',
                required: true,
                validations: ['string', 'required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'dataNascita',
                name: 'dataNascita',
                label: 'Data di Nascita',
                type: 'date',
                required: true,
                validations: ["date", "nullable", 'when'],
                precompiled: false,
                params: {
                    when: [
                        [], {
                            then: (schema) => schema.required("Questo campo è obbligatorio").typeError("La data di nascita non è valida").max(new Date(), "La data di nascita non può essere nel futuro")
                        }
                    ]
                }
            },
            {
                id: 'luogoNascita',
                name: 'luogoNascita',
                label: 'Luogo di Nascita',
                type: 'text',
                required: true,
                validations: ['string', 'required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'nazionalita',
                name: 'nazionalita',
                label: 'Nazionalità',
                type: 'text',
                required: true,
                validations: ['string', 'required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'codiceFiscale',
                name: 'codiceFiscale',
                label: 'Codice Fiscale',
                type: 'text',
                required: true,
                validations: ['string', 'required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'numeroCartaIdentita',
                name: 'numeroCartaIdentita',
                label: 'Numero Carta d\'Identità',
                type: 'text',
                required: true,
                validations: ['string', 'required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'scadenzaCartaIdentita',
                name: 'scadenzaCartaIdentita',
                label: 'Scadenza Carta d\'Identità',
                type: 'date',
                required: true,
                validations: ["date", "nullable", 'when'],
                precompiled: false,
                params: {
                    when: [
                        [], {
                            then: (schema) => schema.required("Questo campo è obbligatorio").typeError("La data di nascita non è valida").min(new Date(), "La data di scadenza non può essere nel passato")
                        }
                    ]
                }
            },
        ]
    },
    {
        id: 'residenzaAtleta',
        name: "residenzaAtleta",
        title: 'Residenza',
        subtitle: "",
        fields: [
            {
                id: 'cittaResidenza',
                name: 'cittaResidenza',
                label: 'Città',
                type: 'text',
                required: true,
                validations: ['string', 'required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'provinciaResidenza',
                name: 'provinciaResidenza',
                label: 'Provincia',
                type: 'text',
                required: true,
                validations: ['string', 'required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'viaResidenza',
                name: 'viaResidenza',
                label: 'Via',
                type: 'text',
                required: true,
                validations: ['string', 'required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'numeroCivicoResidenza',
                name: 'numeroCivicoResidenza',
                label: 'Numero Civico',
                type: 'text',
                required: true,
                validations: ['string', 'required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'capResidenza',
                name: 'capResidenza',
                label: 'C.A.P.',
                type: 'text',
                required: true,
                validations: ['string', 'required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
        ]
    }
]
