import React, {useEffect, useState} from "react";
import HomeLayout from "../layouts/HomeLayout";
import {Link, useNavigate} from "react-router-dom";
import {registrationMap} from "../assets/data/registrationMap";
import {createValidationSchema} from "../functions/createValidationScheme";
import {athleteMap} from "../assets/data/athleteMap";
import {AlertBar} from "../components/AlertBar";
import {Box, Button, CircularProgress, Container, FormControlLabel, Tab, Typography} from "@mui/material";
import {Formik} from "formik";
import {FormContent} from "../components/FormContent";
import {green} from "@mui/material/colors";
import axios from "axios";
import {API_URL} from "../config";
import {Iscrizione} from "../components/Iscrizione";
import {auth} from "../firebase/clientApp";
import {getAuthToken} from "../functions/getAuthToken";
import {ArrowBackIos} from "@mui/icons-material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import {RunningTeam} from "../components/RunningTeam";
import dayjs from "dayjs";

const defaultAthlete = {
    categoria: '',
    tipoIscrizione: '',
    nuovoIscritto: '',
    divisa: '',
    assicurazione: ""
}

const defaultRunningTeam = {
    categoria: ""
}

export const AddAthlete = () => {
    const [loading, setLoading] = React.useState(false)
    const [sending, setSending] = React.useState(false)
    const [initialValues, setInitialValues] = React.useState({})
    const [success, setSuccess] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState("")
    const [costo, setCosto] = React.useState(0)
    const [athlete, setAthlete] = React.useState(defaultAthlete)
    const [runningTeam, setRunningTeam] = React.useState(defaultRunningTeam)
    const [tipo, setTipo] = React.useState("atleta")
    const [privacy, setPrivacy] = useState({
        consenso: false,
        immagine: false
    })

    const handleChangeTab = (event, newValue) => {
        setAthlete(defaultAthlete)
        setRunningTeam(defaultRunningTeam)
        setTipo(newValue);
    };

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        let c = 0
        if (athlete.tipoIscrizione !== "") {
            switch (athlete.tipoIscrizione) {
                case "monosettimanaleMartedi":
                case "monosettimanaleGiovedi":
                    c += 215
                    break
                case "bisettimanale":
                    c += 275
                    break
                case "completo":
                case "assoluti":
                    c += 350
                    break
                default:
                    break
            }
            switch (athlete.divisa) {
                case "tutaZaino":
                    c += 50
                    break
                case "tuta":
                    c += 30
                    break
                case "zaino":
                    c += 25
                    break
                default:
                    break
            }
            if (athlete.assicurazione === "si") {
                c += 30
            }
            /*if (athlete.divisa === "tutaZaino" && athlete.nuovoIscritto === "si" && ["ragazzi", "cadetti"].includes(athlete.categoria)) {
                c -= 5
            }*/
        }
        if (runningTeam.categoria !== "") {
            switch (runningTeam.categoria) {
                case "running3mesi":
                    c += 170
                    break
                case "running5mesi":
                    c += 250
                    break
                case "running10mesi":
                    c += 400
                    break
                default:
                    break
            }
        }
        setCosto(c)
    }, [athlete, runningTeam])

    const getData = async () => {
        setLoading(true)
        let d = {
            /** Mockdata */
            /*"nome": "Matilde",
            "dataNascita": dayjs("1992-07-19"),
            "cognome": "Ugolini",
            "numeroCartaIdentita": "asd",
            "nazionalita": "Italia",
            "scadenzaCartaIdentita": dayjs("2025-02-10"),
            "privacy": true,
            "luogoNascita": "Lugo",
            "fotoEVideo": true,
            "codiceFiscale": "GLN",
            "capResidenza": "234",
            "cittaResidenza": "Lugo",
            "viaResidenza": "asd",
            "numeroCivicoResidenza": "123",
            "provinciaResidenza": "RA",*/
        }
        athleteMap.map(s => {
            return s.fields.filter(f => f.type !== 'note').forEach(f => {
                    let key = f.id
                    if (f.type === 'date' || f.type === 'datetime' || f.type === 'time') {
                        d[key] = d[key] || null
                    } else if (f.type === 'checkbox') {
                        d[key] = d[key] || false
                    } else {
                        d[key] = d[key] || ''
                    }
                }
            )
        })
        setInitialValues(d)
        setLoading(false)
    }

    const handleEditIscrizione = (id, value) => {
        if (id === "categoria") {
            setAthlete(prevState => ({...prevState, tipoIscrizione: ''}))
        }

        if ((id === "categoria" && athlete.nuovoIscritto === "si" && ["ragazzi", "cadetti"].includes(value)) || (id === "nuovoIscritto" && value === 'si' && ["ragazzi", "cadetti"].includes(athlete.categoria))) {
            setAthlete(prevState => ({...prevState, divisa: "tutaZaino"}))
        }
        if (value === "assoluti" || athlete.categoria === "assoluti") {
            setAthlete(prevState => ({...prevState, assicurazione: "si"}))
        }
        setAthlete(prevState => ({...prevState, [id]: value}))
    }

    const handleEditRunningTeam = (id, value) => {
        setRunningTeam(prevState => ({...prevState, [id]: value}))
    }

    const yupSchema = () => {
        let fields = []
        athleteMap.map(s => {
            return s.fields.filter(f => f.type !== 'note').forEach(f => {
                fields.push(f)
            })
        })
        let schema = createValidationSchema(fields)
        return schema
    }

    const sendData = async (values) => {
        setSending(true)
        const token = await getAuthToken()
        let contenuto = {}
        if (athlete.tipoIscrizione !== "") {
            contenuto = {...athlete}
        } else if (runningTeam.categoria !== "") {
            contenuto = {...runningTeam}
        }
        if(athlete.categoria !== 'assoluti') {
            contenuto.societaCollegata = ''
        }
        await axios.post(`${API_URL}subscribe`, {...values, ...contenuto, tipo, stagione: "2024"}, {headers: {'Authorization': `${token}`}})
            .then(res => {
                setSuccess(true)
                window.location.href = res.data.url
            })
            .catch(res => {
                console.log(res.response.data)
                setErrorMessage(res.response.data.message)
                setError(true)
            })

        setSending(false)
    }

    const validateSchema = yupSchema();

    return (
        <HomeLayout page={'Aggiungi iscrizione'}>
            <AlertBar open={error} setOpened={setError} severity={'error'}
                      message={errorMessage !== '' ? errorMessage : "C'è stato un errore, si prega di riprovare"}/>
            <Container sx={{py: 2}}>
                <Box sx={{pb: 2}}>
                    <Button startIcon={<ArrowBackIos/>} variant={'outlined'} component={Link} to={'/'}>
                        Indietro
                    </Button>
                </Box>
                {
                    (loading || Object.keys(initialValues).length <= 0) ?
                        <Box sx={{textAlign: 'center'}}>
                            <CircularProgress/>
                        </Box>
                        :
                        <Formik
                            validationSchema={validateSchema}
                            initialValues={{...initialValues}}
                            validateOnChange={true}
                            validateOnBlur={true}
                            validateOnMount={false}
                            onSubmit={values => {
                                sendData(values)
                            }}
                        >
                            {(formik) =>
                                <form onSubmit={formik.handleSubmit}>
                                    <FormContent formik={formik} formMap={athleteMap}/>
                                    <Box sx={{pb: 1, pt: 2}}>
                                        <Typography fontWeight={'bold'} variant={'h6'}>Iscrizione</Typography>
                                        <Box sx={{width: '5rem', height: '0.3rem', background: "#F96332"}}/>
                                    </Box>
                                    <TabContext value={tipo}>
                                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                            <TabList onChange={handleChangeTab}>
                                                <Tab label="Atleta Pro sesto atletica cernusco" value="atleta"/>
                                                <Tab label="Running Team" value="running"/>
                                            </TabList>
                                        </Box>
                                        <TabPanel value={'atleta'}>
                                            <Iscrizione initialValues={athlete}
                                                        handleEditIscrizione={handleEditIscrizione}/>
                                        </TabPanel>
                                        <TabPanel value={'running'}>
                                            <RunningTeam initialValues={runningTeam}
                                                         handleEditRunningTeam={handleEditRunningTeam}/>
                                        </TabPanel>
                                    </TabContext>
                                    <Box sx={{pb: 1}}>
                                        <Typography fontWeight={'bold'} variant={'h6'}>Privacy</Typography>
                                        <Box sx={{width: '5rem', height: '0.3rem', background: "#F96332"}}/>
                                    </Box>
                                    <FormGroup>
                                        <FormControlLabel required control={<Checkbox/>} label={
                                            <Typography fontSize={13}>
                                                Dichiaro di aver letto e accettato <a href={'/privacy-fidal.pdf'}
                                                                                      target={'_blank'}>l'informativa
                                                sul trattamento dei dati personali</a> necessari al tesseramento presso
                                                la Federazione Italiana di Atletica Leggera - FIDAL
                                            </Typography>
                                        }/>
                                        <FormControlLabel required control={<Checkbox/>} label={
                                            <Typography fontSize={13}>
                                                Dichiaro di aver letto e accettato la <a
                                                href={'/liberatoria-immagine.pdf'} target={'_black'}>liberatoria</a> per
                                                la pubblicazione e trasmissione di immagini
                                            </Typography>}
                                        />
                                    </FormGroup>
                                    <Box sx={{textAlign: 'center', pt: 2}}>
                                        <Typography textAlign={'center'} sx={{pb: 1}}>
                                            Costo totale: <b>{costo}€</b>
                                        </Typography>
                                        {
                                            athlete.categoria==='assoluti' &&
                                                <Typography textAlign={'center'} sx={{pb: 1}} fontWeight={'bold'} variant={'body2'}>
                                                    Se fai parte di una società collegata dovrai inserire il codice ricevuto in precedenza nella pagina di pagamento
                                                </Typography>
                                        }
                                        {
                                            sending ? <CircularProgress/> :
                                                <Button variant={'contained'} type={'submit'}
                                                        disabled={
                                                            tipo === 'atleta' ? Object.values(athlete).includes('') :
                                                                tipo === 'running' ? Object.values(runningTeam).includes('')
                                                                    : false
                                                }>
                                                    Procedi al pagamento
                                                </Button>

                                        }
                                    </Box>
                                </form>
                            }
                        </Formik>
                }
            </Container>
        </HomeLayout>
    )
}
