import React from "react";
import {Box, Typography} from "@mui/material";

export const Title = ({title}) => {
    return (
        <Box sx={{pb: 1}}>
            <Typography fontWeight={'bold'} variant={'h6'}>{title}</Typography>
            <Box sx={{width: '5rem', height: '0.3rem', background: "#F96332"}}/>
        </Box>
    )
}
