import React from "react";
import {Box, Card, CardContent, Container, useTheme} from "@mui/material";

export const LoginLayout = ({children}) => {
    const theme = useTheme();
    return (
        <Box className={'homepage'}>
            <Container maxWidth={'sm'}>
                <Card sx={{background: theme.palette.primary.main}}>
                    <CardContent>
                        {children}
                    </CardContent>
                </Card>
            </Container>
        </Box>
    )
}
