import React from "react";
import {FileUploader} from "react-drag-drop-files";
import {Box, Button, Stack, Tooltip, Typography} from "@mui/material";
import {Check, FileOpen} from "@mui/icons-material";

export const Uploader = ({label, handleChange, file, uploaded, edit}) => {
    return (
        <Box sx={{width: {xs: '100%', md: 'min(25rem, 100%)'}, py: 2}}>
            {uploaded &&
                <Stack mb={2} justifyContent={'center'} alignItems={'center'} direction={'row'} spacing={2}>
                    <Check sx={{color: '#2CA8FF', fontSize: 26}}/>
                    <Typography textAlign={'center'} variant={'body1'}>
                        File caricato
                    </Typography>
                    <Box display={'flex'} justifyContent={'start'} alignItems={'center'}>
                        <Tooltip
                            title={'Apri link in un\'altra finestra'}
                            children={<Button href={uploaded}
                                              target={'_blank'}
                                              color={'primary'}
                                              size={'small'}
                                              variant={'contained'}
                                              sx={{paddingBlock: 1, color: 'white'}}
                                              onClick={(event) => event.stopPropagation()}
                                              endIcon={<FileOpen/>}>
                                {label}
                            </Button>}/>
                    </Box>
                </Stack>
            }
            {
                (!uploaded || edit) &&
                    <FileUploader
                        multiple={false}
                        handleChange={handleChange}
                        name="file"
                        types={["jpg", "JPG", "jpeg", "JPEG", "png", "PNG", "pdf", "PDF"]}
                        label={'Carica o trascina il file qui'}
                        hoverTitle={"Rilascia qui"}
                        children={
                            <Stack alignItems={'center'}
                                   sx={{border: '2px dashed #2CA8FF', borderRadius: '1rem', cursor: 'pointer', py: 5}}>
                                {file === null ?
                                    <Typography textAlign={'center'}>
                                        Nessun file caricato,
                                        <br/>
                                        <u>clicca</u> o trascina il file qui {uploaded && edit ? "per aggiornarlo" : ""}
                                    </Typography>
                                    :
                                    <Typography textAlign={'center'}>
                                        {`File caricato: ${file.name}`}
                                        <br/>
                                        <u>clicca</u> o trascina il file qui per cambiare file
                                    </Typography>
                                }
                            </Stack>
                        }
                    />

            }
        </Box>
    )
}
