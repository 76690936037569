import React from 'react';
import {styled} from "@mui/material/styles";
import {InputBase} from "@mui/material";

export const LoginTextField = styled(InputBase)(({theme}) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: '50rem',
        position: 'relative',
        backgroundColor: '#3FB1FE',
        fontSize: 16,
        width: '100%',
        padding: '10px 18px',
        "&::placeholder": {
            color: 'white',
            opacity: 1
        }
    },
}));
