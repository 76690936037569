import React from "react";
import {Box, Typography} from "@mui/material";

export const VisualLayout = ({children}) => {
    return(
        <Box>
            <Box className={'visual-header'}>
                <Box sx={{width:'10rem', height:'10rem', borderRadius:'50rem'}}>
                    <img style={{width: '100%', height: '100%', objectFit:'cover', overflow:'hidden', borderRadius:'inherit'}} src={require('../assets/images/logo.jpg')}/>
                </Box>
                <Box sx={{pt:2}}>
                    <Typography variant={'h5'} textAlign={'center'} color={'white'} fontWeight={'bold'}>
                        Iscrizioni
                    </Typography>
                    <Typography textTransform={'uppercase'} sx={{opacity:'0.7'}} textAlign={'center'} fontWeight={'bold'} color={'white'}>
                        Atletica Cernusco
                    </Typography>
                </Box>
            </Box>
            {children}
        </Box>
    )
}
