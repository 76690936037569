import React from "react";
import {
    Box, Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem, Radio, RadioGroup,
    Select,
    Typography
} from "@mui/material";

const tariffe = [
    {
        value: "monosettimanaleMartedi",
        nome: "Mono Martedì",
        costo: "215 €",
        per: "eso"
    },
    {
        value: "monosettimanaleGiovedi",
        nome: "Mono Giovedì",
        costo: "215 €",
        per: "eso"
    },
    {
        value: "bisettimanale",
        nome: "Bisettimanale",
        costo: "275 €",
        per: "eso"
    },
    {
        value: "completo",
        nome: "Completo",
        costo: "350 €",
        per: "completo"
    },
    {
        value: "assoluti",
        nome: "Completo",
        costo: "350 €",
        per: "assoluti"
    }
]

export const Iscrizione = ({initialValues, handleEditIscrizione}) => {

    return (
        <Box>
            <Box sx={{pt: 1}}>
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">È un nuovo iscritto?</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue=""
                        name="nuovoIscritto"
                        value={initialValues['nuovoIscritto']}
                        onChange={(e) => handleEditIscrizione('nuovoIscritto', e.target.value)}
                        row
                    >
                        <FormControlLabel value="si" control={<Radio/>} label="Si"/>
                        <FormControlLabel value="no" control={<Radio/>} label="No"/>
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box sx={{width: "min(100%, 20rem)"}}>
                <FormControl fullWidth variant={"standard"}>
                    <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-label"
                        id="categoria"
                        value={initialValues['categoria']}
                        label="Categoria"
                        onChange={(e) => handleEditIscrizione('categoria', e.target.value)}
                    >
                        <MenuItem value={"esordientiPalestra"}>ESO PALESTRA: 2019–2018–2017</MenuItem>
                        <MenuItem value={"esordientiCampo"}>ESO CAMPO: 2016–2015–2014</MenuItem>
                        <MenuItem value={"ragazzi"}>RAGAZZI/E: 2013–2012</MenuItem>
                        <MenuItem value={"cadetti"}>CADETTI/E: 2011–2010</MenuItem>
                        <MenuItem value={"assoluti"}>ASSOLUTI: dal 2009 in poi</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            {initialValues['categoria'] !== "" && <Grid container sx={{pt: 2}} spacing={3}>
                {tariffe.filter(t =>
                    ["esordientiPalestra", "esordientiCampo"].includes(initialValues['categoria']) ?
                        t.per === "eso" :
                        initialValues['categoria'] === 'assoluti' ?
                            t.per === 'assoluti' : t.per === 'completo'
                    ).map((tariffa, index) =>
                    <Grid item xs={12} md={4} onClick={() => handleEditIscrizione('tipoIscrizione', tariffa.value)}>
                        <Box
                            className={`tariffa ${initialValues['tipoIscrizione'] === tariffa.value && "tariffa-attiva"}`}>
                            <Typography textAlign={'center'} variant={'h6'}>
                                {tariffa.nome}
                            </Typography>
                            <Typography textAlign={'center'}>
                                Quota annuale comprensiva di tesseramento e corso
                            </Typography>
                            <Typography textAlign={'center'} fontWeight={'bold'}>
                                {tariffa.costo}
                            </Typography>
                        </Box>
                    </Grid>
                )}
            </Grid>}
            {initialValues['categoria'] === 'assoluti' && <Box sx={{pt: 3}}>
                <FormControl>
                    <FormLabel id="societaCollegata-label">Fa parte di una società collegata?</FormLabel>
                    <RadioGroup
                        aria-labelledby="societaCollegata-label"
                        defaultValue=""
                        name="societaCollegata"
                        value={initialValues['societaCollegata']}
                        onChange={(e) => handleEditIscrizione('societaCollegata', e.target.value)}
                        row
                    >
                        <FormControlLabel value="si" control={<Radio/>} label="Si"/>
                        <FormControlLabel value="no" control={<Radio/>} label="No"/>
                    </RadioGroup>
                </FormControl>
            </Box>}
            <Box sx={{pt: 3}}>
                <FormControl
                    disabled={initialValues['nuovoIscritto'] === "si" && ["ragazzi", "cadetti"].includes(initialValues['categoria'])}>
                    <FormLabel id="demo-radio-buttons-group-label">Acquisto divisa</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue=""
                        name="divisa"
                        value={initialValues['divisa']}
                        onChange={(e) => handleEditIscrizione('divisa', e.target.value)}
                    >
                        <FormControlLabel value="tutaZaino" control={<Radio/>} label="Completa: tuta + zaino 50€"/>
                        <FormControlLabel value="tuta" control={<Radio/>} label="Tuta 30€"/>
                        <FormControlLabel value="zaino" control={<Radio/>} label="Zaino 25€"/>
                        <FormControlLabel value="no" control={<Radio/>} label="NON sono interessato"/>
                    </RadioGroup>
                </FormControl>
                {
                    initialValues['categoria'] === 'assoluti' &&
                    <Typography fontWeight={'bold'}>
                        Per gli iscritti alla
                        categoria {initialValues['categoria'].charAt(0).toUpperCase() + initialValues['categoria'].slice(1)} l'acquisto
                        della divisa è obbligatoria {initialValues['categoria'] === 'assoluti' && "se non se ne possiede già una"}
                    </Typography>
                }
                {initialValues['nuovoIscritto'] === "si" && ["ragazzi", "cadetti"].includes(initialValues['categoria']) &&
                    <Typography fontWeight={'bold'}>
                        Per i nuovi iscritti alla categoria {initialValues['categoria'].charAt(0).toUpperCase() + initialValues['categoria'].slice(1)} l'acquisto della divisa è obbligatoria
                    </Typography>}
            </Box>

            <Box sx={{pt: 3}}>
                <FormControl
                    disabled={["assoluti"].includes(initialValues['categoria'])}
                >
                    <FormLabel id="demo-radio-buttons-group-label">Assicurazione personale aggiuntiva</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue=""
                        name="assicurazione"
                        value={initialValues['assicurazione']}
                        onChange={(e) => handleEditIscrizione('assicurazione', e.target.value)}
                    >
                        <FormControlLabel value="si" control={<Radio/>} label="Acquisto assicurazione (costo 30€)"/>
                        <FormControlLabel value="no" control={<Radio/>} label="NON sono interessato"/>
                    </RadioGroup>
                </FormControl>
                {["assoluti"].includes(initialValues['categoria']) &&
                    <Typography fontWeight={'bold'}>
                        Per gli iscritti alla
                        categoria {initialValues['categoria'].charAt(0).toUpperCase() + initialValues['categoria'].slice(1)} l'assicurazione è obbligatoria.
                    </Typography>}
            </Box>
        </Box>
    )
}
