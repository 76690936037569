import React, {useEffect, useState} from "react";
import {Box, Button, Divider, Grid, Typography} from "@mui/material";
import HomeLayout from "../layouts/HomeLayout";
import {useQuery} from "../custom-hooks/useQuery";
import {AlertBar} from "../components/AlertBar";
import {Add} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {AthleteCard} from "../components/AthleteCard";
import {useAthletesStore} from "../store/athletesStore";
import {orderBy} from 'lodash'

export const Home = () => {
    const query = useQuery()
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const {athletes} = useAthletesStore()

    useEffect(() => {
        query.get("success")==="true" && setSuccess(true)
        query.get("error")==="true" && setError(true)
    },[query])


    return(
        <HomeLayout page={"Iscrizione atleti"}>
            <AlertBar open={success} setOpened={setSuccess} severity={"success"} message={"Pagamento effettuato con successo"}/>
            <AlertBar open={error} setOpened={setError} severity={"error"} message={"C'è stato un errore durante il pagamento, si prega di riprovare"}/>
            <Box>
                <Box sx={{pb:2}}>
                    <Button startIcon={<Add/>} variant={'contained'} color={'secondary'} component={Link} to={'/aggiungi-atleta'}>
                        Aggiungi iscrizione (2024)
                    </Button>
                </Box>
                <Typography sx={{pt:1}} variant={'h6'} fontWeight={'bold'}>
                    Atleti iscritti
                </Typography>
                <Divider/>
                <Grid container sx={{pt:2}} spacing={1}>
                    {
                        athletes.length > 0 && orderBy(athletes, 'stagione', 'desc').map((athlete, index) => (
                            <Grid item xs={12} md={6} lg={3} key={index}>
                                <AthleteCard athlete={athlete}/>
                            </Grid>
                        ))
                    }
                </Grid>
            </Box>
        </HomeLayout>
    )
}
