import React from "react";
import HomeLayout from "../layouts/HomeLayout";
import {useUserStore} from "../store/userStore";
import {Title} from "../components/Title";
import {Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";

function createData(name, value) {
    return { name, value };
}

export const Profile = () => {
    const {user} = useUserStore()

    const rows = [
        createData('Nome', user.nome),
        createData('Cognome', user.cognome),
        createData('Email', user.email),
    ]

    return(
        <HomeLayout page={"Profilo"}>
            <Title title={"I tuoi dati"}/>
            <Box sx={{width:'min(30rem, 100%)'}}>
                <TableContainer component={Paper}>
                    <Table  aria-label="simple table">
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <b>{row.name}</b>
                                    </TableCell>
                                    <TableCell align="left">{row.value}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </HomeLayout>
    )
}
