import React, {useState} from "react";
import {alpha, styled} from '@mui/material/styles';
import {
    Box,
    Button,
    Card,
    CardContent, CircularProgress,
    Container, Divider,
    InputBase,
    Stack,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {LoginLayout} from "../layouts/LoginLayout";
import {auth} from "../firebase/clientApp";
import {AlertBar} from "../components/AlertBar";
import {LoginTextField} from "../components/LoginTextField";

export const Login = () => {
    const theme = useTheme()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)
    const navigate = useNavigate()

    const handleLogin = async (e) => {
        e.preventDefault()
        setSending(true)
        await auth.signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                navigate('/')
            })
            .catch((error) => {
                setError(true)
            })
        setSending(false)
    }

    return (
        <LoginLayout>
            <AlertBar open={error} setOpened={setError} severity={'error'} message={"Credenziali errate"}/>
            <Typography variant={'h6'} textAlign={'center'} color={'white'} fontWeight={'bold'}>
                Inserisci le tue credenziali per accedere
            </Typography>
            <form onSubmit={handleLogin}>
                <Container maxWidth={'xs'} sx={{py: 2}}>
                    <Stack spacing={2}>
                        <LoginTextField required sx={{color: 'white'}} type={'email'} placeholder={'Email'}
                                        onChange={(e) => setEmail(e.target.value)} variant={"outlined"}/>
                        <LoginTextField required sx={{color: 'white'}} type={'password'} placeholder={'Password'}
                                        onChange={(e) => setPassword(e.target.value)} variant={"outlined"}/>
                        <Box sx={{textAlign: 'center'}}>
                            {sending ?
                                <CircularProgress sx={{color:'white'}}/>
                                :
                                <Button sx={{borderRadius: '50rem'}} variant={'contained'} color={'secondary'}
                                     type={'submit'}>
                                Accedi
                            </Button>}
                        </Box>
                    </Stack>
                </Container>
            </form>
            <Box>
                <Typography variant={'body2'} textAlign={'center'} color={'white'}>
                    <a href={'/recover'}>Password dimenticata?</a>
                </Typography>
            </Box>
            <Container maxWidth={'xs'} sx={{py: 3}}>
                <Box sx={{borderBottom: '1px solid white', width: '100%', height: '1px'}}/>
            </Container>
            <Box>
                <Typography variant={'body2'} textAlign={'center'} color={'white'}>
                    Non hai un account? <Link to={'/register'}>Registrati</Link>
                </Typography>
            </Box>
        </LoginLayout>
    )
}
