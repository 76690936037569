import React from "react";
import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";

export const RunningTeam = ({initialValues, handleEditRunningTeam}) => {
  return(
      <Box>
          <Box sx={{width: "min(100%, 20rem)"}}>
              <FormControl fullWidth variant={"standard"}>
                  <InputLabel id="demo-simple-select-label">Durata</InputLabel>
                  <Select
                      required
                      labelId="demo-simple-select-label"
                      id="durata"
                      value={initialValues['categoria']}
                      label="Durata"
                      onChange={(e) => handleEditRunningTeam('categoria', e.target.value)}
                  >
                      <MenuItem value={"running3mesi"}>3 MESI: 170€</MenuItem>
                      <MenuItem value={"running5mesi"}>5 MESI: 250€</MenuItem>
                      <MenuItem value={"running10mesi"}>10 MESI: 400€</MenuItem>
                  </Select>
              </FormControl>
          </Box>
      </Box>
  )
}
