import logo from './logo.svg';
import './App.css';
import {Route, Routes} from "react-router-dom";
import {Home} from "./pages/Home";
import {Login} from "./pages/Login";
import {Register} from "./pages/Register";
import ProtectedRoute from "./components/ProtectedRoute";
import {Recover} from "./pages/Recover";
import HomeLayout from "./layouts/HomeLayout";
import {AddAthlete} from "./pages/AddAthlete";
import {Upload} from "./pages/Upload";
import {Profile} from "./pages/Profile";

function App() {
  return (
    <div>
      <Routes>
          <Route path="/login" element={<Login/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path="/recover" element={<Recover/>}/>
          <Route element={<ProtectedRoute/>}>
              <Route path="/" element={<Home/>}/>
              <Route path="/profilo" element={<Profile/>}/>
              <Route path="/aggiungi-atleta" element={<AddAthlete/>}/>
              <Route path="/upload/:id" element={<Upload/>}/>
          </Route>
      </Routes>
    </div>
  );
}

export default App;
