import React, {useState} from "react";
import {Box, Button, CircularProgress, Container, Stack, Typography} from "@mui/material";
import {LoginLayout} from "../layouts/LoginLayout";
import {AlertBar} from "../components/AlertBar";
import {auth} from "../firebase/clientApp";
import {LoginTextField} from "../components/LoginTextField";
import {Link} from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const Recover = () => {
    const [email, setEmail] = useState('')
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const handleRecover = async (e) => {
        e.preventDefault()
        setSending(true)
        await auth.sendPasswordResetEmail(email)
            .then(() => {
                setSuccess(true)
            })
            .catch((error) => {
                if(error.code === 'auth/user-not-found'){
                    setErrorMessage('Utente non iscritto')
                }
                setError(true)
            })
        setSending(false)
    }

    return (
        <Box>
            <LoginLayout>
                <AlertBar open={error} setOpened={setError} severity={'error'} message={errorMessage !== '' ? errorMessage : "Credenziali errate"}/>
                <Typography variant={'h6'} textAlign={'center'} color={'white'} fontWeight={'bold'}>
                    Inserisci il tuo indizzo mail per reimpostare la password
                </Typography>
                <Button component={Link} variant={'outlined'} color={'secondary'} to={'/login'} size={'small'}
                        sx={{borderRadius: '50rem'}} startIcon={<ArrowBackIosIcon/>}>
                    Torna al login
                </Button>
                <Box sx={{pt:2}}>
                    <form onSubmit={handleRecover}>
                        <Container maxWidth={'xs'} sx={{py: 2}}>
                            <Stack spacing={2}>
                                <LoginTextField required sx={{color: 'white'}} type={'email'} placeholder={'Email'}
                                                onChange={(e) => setEmail(e.target.value)} variant={"outlined"}/>
                                <Box sx={{textAlign: 'center'}}>
                                    {success ?
                                        <Typography sx={{color: 'white'}} variant={'body1'}>
                                            Email inviata con successo
                                        </Typography>
                                        :
                                        sending ?
                                            <CircularProgress sx={{color: 'white'}}/>
                                            :
                                            <Button sx={{borderRadius: '50rem'}} variant={'contained'} color={'secondary'}
                                                    type={'submit'}>
                                                Reimposta password
                                            </Button>
                                    }
                                </Box>
                            </Stack>
                        </Container>
                    </form>
                </Box>
            </LoginLayout>
        </Box>
    )
}
