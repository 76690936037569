import React from "react";
import {
    Accordion,
    AccordionDetails, AccordionSummary,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import {Check, Close, ExpandMore} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import {Link} from "react-router-dom";
import dayjs from "dayjs";

export const AthleteCard = ({athlete}) => {
    const theme = useTheme()

    return(
        <Box>
            <Card sx={{ minWidth: 275, border:'2px solid #2CA8FF', borderRadius:'1rem'}}>
                <CardContent>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'start'}>
                        <Stack>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Atleta
                            </Typography>
                            <Typography variant="h5" component="div">
                                {athlete.nome} {athlete.cognome}
                            </Typography>
                        </Stack>
                        <Chip label={`STAGIONE ${athlete.stagione}`} color={parseInt(athlete.stagione) <= 2023 ? 'default' : 'primary'}/>
                    </Stack>
                    <Accordion elevation={0} defaultExpanded={athlete.stagione === '2024'}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography sx={{fontSize: 14, mt: 1.5 }} color="text.secondary">
                                Stato iscrizione:
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List dense>
                                <ListItem>
                                    <ListItemIcon>
                                        {athlete.pagamento ? <Check color={'success'}/> : <Close color={'error'}/> }
                                    </ListItemIcon>
                                    <ListItemText primary="Pagato" sx={{color:athlete.pagamento ? theme.palette.success.main : theme.palette.error.main}}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        {(athlete.certificato
                                            && dayjs(athlete.scadenzaCertificato).isAfter(dayjs())
                                        ) ? <Check color={'success'}/> : <Close color={'error'}/> }
                                    </ListItemIcon>
                                    <ListItemText primary="Certificato medico" sx={{color:
                                            (athlete.certificato && dayjs(athlete.scadenzaCertificato).isAfter(dayjs()))
                                                ? theme.palette.success.main : theme.palette.error.main}}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        {athlete.fototessera ? <Check color={'success'}/> : <Close color={'error'}/> }
                                    </ListItemIcon>
                                    <ListItemText primary="Foto tessera" sx={{color:athlete.fototessera ? theme.palette.success.main : theme.palette.error.main}}/>
                                </ListItem>
                            </List>
                            <Typography color={(athlete.pagamento && athlete.fototessera && athlete.certificato) ? "success" : "error"} variant={'h6'} textAlign={'center'}>
                                {(athlete.pagamento && athlete.fototessera && athlete.certificato) ? "Iscrizione completa" : "Iscrizione incompleta"}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
                <CardActions>
                    <Button size="small" variant={'contained'} color={'secondary'} component={Link} to={`/upload/${athlete.id}`}>
                        {(parseInt(athlete.stagione) <= 2023 || (athlete.pagamento && athlete.fototessera && athlete.certificato)) ? "Consulta iscrizione" : "Completa iscrizione"}
                    </Button>
                </CardActions>
            </Card>
        </Box>
    )
}
