import React, {useEffect} from "react";
import {Box, Button, CircularProgress, Container, Snackbar, Typography} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {VisualLayout} from "../layouts/VisualLayout";
import {createValidationSchema} from "../functions/createValidationScheme";
import {registrationMap} from "../assets/data/registrationMap";
import {FormContent} from "../components/FormContent";
import {useNavigate, Link} from "react-router-dom";
import {Formik} from "formik";
import axios from "axios";
import {green} from "@mui/material/colors";
import {API_URL} from "../config";
import {AlertBar} from "../components/AlertBar";



export const Register = () => {
    const [loading, setLoading] = React.useState(false)
    const [sending, setSending] = React.useState(false)
    const [initialValues, setInitialValues] = React.useState({})
    const [success, setSuccess] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState("")
    const navigate = useNavigate()

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        setLoading(true)
        let d = {}
        registrationMap.map(s => {
            return s.fields.filter(f => f.type !== 'note').forEach(f => {
                    let key = f.id
                    if (f.type === 'date' || f.type === 'datetime' || f.type === 'time') {
                        d[key] = null
                    } else if (f.type === 'checkbox') {
                        d[key] = false
                    } else {
                        d[key] = ''
                    }
                }
            )
        })
        setInitialValues(d)
        setLoading(false)
    }

    const yupSchema = () => {
        let fields = []
        registrationMap.map(s => {
            return s.fields.filter(f => f.type !== 'note').forEach(f => {
                fields.push(f)
            })
        })
        let schema = createValidationSchema(fields)
        return schema
    }

    const validateSchema = yupSchema();

    const sendData = async (values) => {
        setSending(true)
        await axios.post(`${API_URL}register`, values)
            .then(res => setSuccess(true))
            .catch(res => {
                setErrorMessage(res.response.data.error)
                setError(true)
            })
        setSending(false)
    }

    return (
        <VisualLayout>
            <AlertBar open={error} setOpened={setError} severity={'error'} message={errorMessage}/>
            <Container sx={{py:2}}>
                {
                    (loading || Object.keys(initialValues).length <= 0) ?
                        <Box sx={{textAlign: 'center'}}>
                            <CircularProgress/>
                        </Box>
                        :
                        <Formik
                            validationSchema={validateSchema}
                            initialValues={{...initialValues}}
                            validateOnChange={true}
                            validateOnBlur={true}
                            validateOnMount={false}
                            onSubmit={values => {
                                sendData(values)
                            }}
                        >
                            {(formik) =>
                                <form onSubmit={formik.handleSubmit}>
                                    <FormContent formik={formik} formMap={registrationMap}/>
                                    <Box sx={{textAlign: 'center', pt: 2}}>
                                        {
                                            success ?
                                                <Box>
                                                    <Typography variant={'h6'} fontWeight={'bold'} textAlign={'center'}
                                                                color={green[400]}>
                                                        Registrazione avvenuta con successo
                                                    </Typography>
                                                    <Box>
                                                        <Button variant={'contained'} component={Link} to={'/login'}>
                                                            Torna al login
                                                        </Button>
                                                    </Box>
                                                </Box>
                                                :
                                                sending ? <CircularProgress/> :
                                                    <Button variant={'contained'} type={'submit'}>
                                                        Registrati
                                                    </Button>

                                        }
                                    </Box>
                                </form>
                            }
                        </Formik>
                }
            </Container>
        </VisualLayout>
    )
}
