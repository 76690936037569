const yup = require("yup");
export const registrationMap = [
    {
        id: 'registrazioneUtente',
        name: "registrazioneUtente",
        title: 'Completa le informazioni per registrarti',
        subtitle: "",
        fields: [
            {
                id: 'nome',
                name: 'nome',
                label: 'Nome',
                type: 'text',
                required: true,
                validations: ['string','required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'cognome',
                name: 'cognome',
                label: 'Cognome',
                type: 'text',
                required: true,
                validations: ['string','required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'telefono',
                name: 'telefono',
                label: 'Telefono',
                type: 'text',
                required: true,
                validations: ['string','required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'codiceFiscale',
                name: 'codiceFiscale',
                label: 'Codice Fiscale',
                type: 'text',
                required: true,
                validations: ['string','required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'email',
                name: 'email',
                label: 'Email',
                type: 'email',
                required: true,
                validations: ['string','required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'ripetiMail',
                name: 'ripetiMail',
                label: 'Ripeti la mail',
                type: 'email',
                required: true,
                validations: ['string','required','when'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio",
                    when: [
                        [],{
                            then: (schema) => schema.oneOf([yup.ref('email'), null], 'Le mail devono coincidere')
                        }
                    ]
                }
            },
            {
                id: 'password',
                name: 'password',
                label: 'Password',
                type: 'password',
                required: true,
                validations: ['string','required', "when"],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio",
                    when: [
                        [], {
                            then: (schema) => schema
                                .min(8, 'La password deve essere di almeno 8 caratteri')
                                .matches(/[0-9]/, 'La password deve contenere almeno un numero')
                                .matches(/[a-z]/, 'La password deve contenere almeno una lettera minuscola')
                                .matches(/[A-Z]/, 'La password deve contenere almeno una lettera maiuscola')
                                .matches(/[^\w]/, 'La password deve contenere almeno un carattere speciale'),
                        }
                    ]
                }
            },
            {
                id: 'ripetiPassword',
                name: 'ripetiPassword',
                label: 'Ripeti la password',
                type: 'password',
                required: true,
                validations: ['string','required',"when"],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio",
                    when: [
                        [],{
                            then: (schema) => schema.oneOf([yup.ref('password'), null], 'Le password devono coincidere')
                        }
                    ]
                }
            },
        ]
    }
]
